import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';

import { headData, footerData } from '../mock/data';
import '../style/main.scss';
import Footer from '../components/Footer/Footer';
import { PortfolioProvider } from '../context/context';

export default () => {
  const { lang, description, author } = headData;

  const [footer, setFooter] = useState({});

  useEffect(() => {
    setFooter({ ...footerData });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page not found</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || `Page not found`} />
        <meta name="author" content={author} />
      </Helmet>
      <PortfolioProvider value={{ footer }}>
        <section style={{ borderRadius: 0 }} id="hero-404" className="jumbotron">
          <Container>
            <Fade bottom duration={1000} delay={500} distance="30px">
              <h1 className="hero-title-404 m-linkitem text-center">
                404 - Sorry, this path does not exist <br />
                <span role="img" aria-label="emoji">
                  😞
                </span>
              </h1>
            </Fade>
            <Fade bottom duration={1000} delay={1000} distance="30px">
              <p className="hero-cta-404 justify-content-center">
                <Link style={{ margin: 0 }} className="cta-btn cta-btn--hero" to="/">
                  Go back
                </Link>
              </p>
            </Fade>
          </Container>
        </section>
        <Footer hero="hero-404" />
      </PortfolioProvider>
    </>
  );
};
